import { Link } from 'gatsby';
import React from 'react';
import { useLocation } from '@reach/router'; // this helps tracking the location
import { SIGN_IN_URL } from '../../constants';
import viralTrends from '../../images/viralTrends.svg';
import viralTrendsBlack from '../../images/viralTrendsBlack.svg';
import ButtonLink from './ButtonLink';
import Button from './Button';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import * as styles from './Header.module.scss';
import { clsx, urlJoin } from '../../utils/utils';
import { useGlobalState } from '../../state/globalStateContext';

export interface HeaderProps {
  signInButtonText: string;
  anchorButtonText?: string;
  isVslPage?: boolean;
  onAnchorButtonClick?: () => void;
}

const Header = ({
  signInButtonText,
  anchorButtonText,
  isVslPage,
  onAnchorButtonClick,
}: HeaderProps): React.ReactElement => {
  const { theme } = useGlobalState();
  const location = useLocation();
  const { homepageSlug, hideSignInButton } = useGlobalState();

  function renderCtaButton() {
    return (
      <Button className={styles.button} tabIndex={-1}>
        {anchorButtonText ? anchorButtonText : ''}
      </Button>
    );
  }

  return (
    <header
      className={clsx(styles.header, isVslPage && styles.vslPage)}
      data-theme={theme === 'light' ? 'light' : ''}
    >
      <div className={styles.headerContainer}>
        <Link to={!isVslPage ? urlJoin(homepageSlug) : '/'} className={styles.logoContainer}>
          <img src={isVslPage && theme === 'light' ? viralTrendsBlack : viralTrends} alt="" />
          <div className={styles.visuallyHidden}>Viral Trends</div>
        </Link>
        <div className={styles.buttonsContainer}>
          {!hideSignInButton && (
            <ButtonLink
              sendFullReferrer
              secondary
              className={styles.button}
              to={SIGN_IN_URL}
              onClick={() => {
                if (typeof window !== 'undefined' && Array.isArray(window.dataLayer)) {
                  window.dataLayer.push({
                    event: 'sign-in-button-click',
                    context: 'header',
                    urlPath: location.pathname,
                  });
                }
              }}
            >
              {signInButtonText}
            </ButtonLink>
          )}
          {anchorButtonText &&
            (location.pathname === urlJoin(homepageSlug) ? (
              <AnchorLink
                href={'#hero-anchor'}
                onClick={() => {
                  if (typeof window !== 'undefined' && Array.isArray(window.dataLayer)) {
                    window.dataLayer.push({
                      event: 'try-now-button-click',
                      context: 'header',
                      urlPath: location.pathname,
                    });
                  }
                  if (onAnchorButtonClick) {
                    onAnchorButtonClick();
                  }
                }}
              >
                {renderCtaButton()}
              </AnchorLink>
            ) : (
              <Link
                to={urlJoin(homepageSlug) + '#hero-anchor'}
                onClick={() => {
                  window.sessionStorage.setItem('shouldAnimateHeroButton', 'true');
                  if (typeof window !== 'undefined' && Array.isArray(window.dataLayer)) {
                    window.dataLayer.push({
                      event: 'try-now-button-click',
                      context: 'header',
                      urlPath: location.pathname,
                    });
                  }
                }}
              >
                {renderCtaButton()}
              </Link>
            ))}
        </div>
      </div>
    </header>
  );
};

export default Header;
