import React from 'react';
import { Link } from 'gatsby';

import PageSEO from '../components/PageSEO';
import { useGlobalState } from '../state/globalStateContext';
import Layout from '../components/ui/Layout';

import * as styles from './404.module.scss';

const Page404 = (): React.ReactElement => {
  const { theme } = useGlobalState();

  return (
    <Layout isVslPage>
      <div className={styles.container} data-theme={theme === 'light' ? 'light' : ''}>
        <PageSEO />
        <div className={styles.errorContainer}>
          <span className={styles.error}>404</span>
          <h2 className={styles.errorTitle}>Page not found</h2>
          <p className={styles.errorText}>
            This address does not correspond to any page on our website. {' ' + ' '}
            <Link to="/">Go to the main page here.</Link>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Page404;
