// extracted by mini-css-extract-plugin
export var button = "Header-module--button--2014e";
export var buttonsContainer = "Header-module--buttonsContainer--99fe0";
export var header = "Header-module--header--3c27c";
export var headerContainer = "Header-module--headerContainer--28a42";
export var logoContainer = "Header-module--logoContainer--ca573";
export var textStyleLargeTitle = "Header-module--text-style-large-title--ea810";
export var textStyleLargestTitle = "Header-module--text-style-largest-title--17afc";
export var textStyleMediumTitle = "Header-module--text-style-medium-title--91cb4";
export var textStyleSmallTitle = "Header-module--text-style-small-title--5facf";
export var visuallyHidden = "Header-module--visuallyHidden--c08f3";
export var vslPage = "Header-module--vslPage--b9e95";