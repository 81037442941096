import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { DEFAULT_SCHEDULE_ONCE_CALENDAR, SHOULD_RUN_GOOGLE_OPTIMIZE } from '../constants';
import { useGlobalState } from '../state/globalStateContext';

export function useActivateGoogleOptimize(): boolean {
  const [isHidden, setIsHidden] = useState(SHOULD_RUN_GOOGLE_OPTIMIZE);
  // Initialize google optimize experiment on 'optimize.activate'
  useEffect(() => {
    if (SHOULD_RUN_GOOGLE_OPTIMIZE) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'optimize.activate' });
      setIsHidden(false);
    }
  }, []);
  return isHidden;
}

export function useStoreScheduleOnceCalendarOnLoad(
  extraCalendarParams?: Array<{
    paramName: string;
    paramToCalendarId: Record<string, string>;
  }>,
): void {
  const { setScheduleOnceCalendar } = useGlobalState();
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let scheduleOnceCalendar = urlSearchParams.get('socalendar');

    if (scheduleOnceCalendar) {
      // store in local storage
      window.localStorage.setItem('socalendar', scheduleOnceCalendar);
    } else if (extraCalendarParams) {
      for (const { paramName, paramToCalendarId } of extraCalendarParams) {
        const calendarParam = urlSearchParams.get(paramName);
        if (calendarParam && paramToCalendarId[calendarParam]) {
          scheduleOnceCalendar = paramToCalendarId[calendarParam];
          break;
        }
      }
    }

    if (scheduleOnceCalendar) {
      // store in global state
      setScheduleOnceCalendar(scheduleOnceCalendar);
    } else {
      // get from local storage to global state
      setScheduleOnceCalendar(
        window.localStorage.getItem('socalendar') || DEFAULT_SCHEDULE_ONCE_CALENDAR,
      );
    }
  }, []);
}

export function useStoreUTMParamsOnLoad(): void {
  const {
    setUtmSource,
    setUtmMedium,
    setUtmCampaign,
    setUtmTerm,
    setUtmContent,
    setUtmDevice,
    setUtmTarget,
  } = useGlobalState();
  useEffect(() => {
    const utmParamNamesToSetFunction = {
      utm_source: setUtmSource,
      utm_medium: setUtmMedium,
      utm_campaign: setUtmCampaign,
      utm_term: setUtmTerm,
      utm_content: setUtmContent,
      utm_device: setUtmDevice,
      utm_target: setUtmTarget,
    };
    const utmParamNames = Object.keys(utmParamNamesToSetFunction);

    const urlSearchParams = new URLSearchParams(window.location.search);

    const urlHasUtmParams =
      utmParamNames.map(utmParamName => urlSearchParams.get(utmParamName)).filter(Boolean).length >
      0;

    if (urlHasUtmParams) {
      for (const utmParamName of utmParamNames) {
        const utmParamValue = urlSearchParams.get(utmParamName);
        if (utmParamValue) {
          // store in global state
          utmParamNamesToSetFunction[utmParamName](utmParamValue);
          if (window.localStorage) {
            // store in local storage
            window.localStorage.setItem(utmParamName, utmParamValue);
          }
        } else if (window.localStorage) {
          // remove from local storage
          window.localStorage.removeItem(utmParamName);
        }
      }
    } else if (window.localStorage) {
      // get from local storage to global state
      setUtmSource(window.localStorage.getItem('utm_source'));
      setUtmMedium(window.localStorage.getItem('utm_medium'));
      setUtmCampaign(window.localStorage.getItem('utm_campaign'));
      setUtmTerm(window.localStorage.getItem('utm_term'));
      setUtmContent(window.localStorage.getItem('utm_content'));
      setUtmDevice(window.localStorage.getItem('utm_device'));
      setUtmTarget(window.localStorage.getItem('utm_target'));
    }
  }, []);
}

export function useStoreReferrerOnLoad(): void {
  const { setWebsiteReferrer } = useGlobalState();
  useEffect(() => {
    const websiteReferrer = document.referrer;
    const websiteReferrerHost = websiteReferrer ? new URL(websiteReferrer).host : null;
    const isReferrerCurrentWebsite = websiteReferrerHost === window.location.host;
    if (websiteReferrer && !isReferrerCurrentWebsite) {
      // store in global state
      setWebsiteReferrer(websiteReferrer);
      if (window.localStorage) {
        // store in local storage
        window.localStorage.setItem('website_referrer', websiteReferrer);
      }
    } else if (window.localStorage) {
      // get from local storage to global state
      setWebsiteReferrer(window.localStorage.getItem('website_referrer'));
    }
  }, []);
}

export interface TrackingData {
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  utmTerm: string | null;
  utmContent: string | null;
  utmDevice: string | null;
  utmTarget: string | null;
  websiteReferrer: string | null;
}

export function useTrackingData(): TrackingData {
  const {
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    utmDevice,
    utmTarget,
    websiteReferrer,
  } = useGlobalState();
  return {
    utmSource,
    utmMedium,
    utmCampaign,
    utmTerm,
    utmContent,
    utmDevice,
    utmTarget,
    websiteReferrer,
  };
}

export function useSingleRunCallback(callBack: () => void): void {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
}

export interface DimensionObject {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

function getDimensionObject(node: HTMLElement): DimensionObject {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    // @ts-ignore
    top: 'x' in rect ? rect.x : rect.top,
    // @ts-ignore
    left: 'y' in rect ? rect.y : rect.left,
    // @ts-ignore
    x: 'x' in rect ? rect.x : rect.left,
    // @ts-ignore
    y: 'y' in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}
export interface UseDimensionsOptions {
  measureOnResize?: boolean;
  measureOnScroll?: boolean;
}

export function useDimensions(
  ref: React.MutableRefObject<HTMLElement | null>,
  { measureOnResize = true, measureOnScroll = true }: UseDimensionsOptions,
): DimensionObject | null {
  const [dimensions, setDimensions] = useState<DimensionObject | null>(null);
  useLayoutEffect(() => {
    const node = ref.current;
    if (node) {
      const measure = () =>
        window.requestAnimationFrame(() => setDimensions(getDimensionObject(node)));
      measure();

      if (measureOnResize) {
        window.addEventListener('resize', measure);
      }
      if (measureOnScroll) {
        window.addEventListener('scroll', measure);
      }

      return () => {
        if (measureOnResize) {
          window.removeEventListener('resize', measure);
        }
        if (measureOnScroll) {
          window.removeEventListener('scroll', measure);
        }
      };
    }
    return;
  }, [ref.current]);

  return dimensions;
}

export function useWindowDimensions() {
  const [dimensions, setDimensions] = useState({
    height: typeof window !== 'undefined' ? window.innerHeight : null,
    width: typeof window !== 'undefined' ? window.innerWidth : null,
  });
  useLayoutEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return dimensions;
}

export function useScrollY(): number | null {
  const [scrollY, setScrollY] = useState<number | null>(null);
  useLayoutEffect(() => {
    const getScrollY = () => window.requestAnimationFrame(() => setScrollY(window.scrollY));
    getScrollY();
    window.addEventListener('scroll', getScrollY);
    return () => window.removeEventListener('scroll', getScrollY);
  }, []);

  return scrollY;
}
