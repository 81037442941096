import { Link } from 'gatsby';
import React from 'react';
import Button, { ButtonProps } from './Button';

import * as styles from './ButtonLink.module.scss';

export interface ButtonLinkProps extends ButtonProps {
  to: string;
  sendFullReferrer?: boolean;
}

const ButtonLink = ({
  to,
  sendFullReferrer,
  ...restProps
}: ButtonLinkProps): React.ReactElement => {
  if (to.match(/^(https?|tel|mailto):\/\//)) {
    return (
      <a
        className={styles.link}
        href={to}
        referrerPolicy={sendFullReferrer ? 'unsafe-url' : undefined}
      >
        <Button {...restProps}></Button>
      </a>
    );
  } else {
    return (
      <Link className={styles.link} to={to}>
        <Button {...restProps}></Button>
      </Link>
    );
  }
};

export default ButtonLink;
