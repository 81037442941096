// extracted by mini-css-extract-plugin
export var copyrightContainer = "Footer-module--copyrightContainer--9431f";
export var footer = "Footer-module--footer--acb34";
export var footerContainer = "Footer-module--footerContainer--ac627";
export var hookPointLink = "Footer-module--hookPointLink--20c14";
export var line = "Footer-module--line--8f795";
export var noBackground = "Footer-module--noBackground--cbb5f";
export var noPrivacyPolicy = "Footer-module--noPrivacyPolicy--e51cc";
export var privacyPolicyLink = "Footer-module--privacyPolicyLink--1a303";
export var textStyleLargeTitle = "Footer-module--text-style-large-title--17471";
export var textStyleLargestTitle = "Footer-module--text-style-largest-title--e554d";
export var textStyleMediumTitle = "Footer-module--text-style-medium-title--97155";
export var textStyleSmallTitle = "Footer-module--text-style-small-title--20e84";
export var withPageTheme = "Footer-module--withPageTheme--05f45";