// extracted by mini-css-extract-plugin
export var container = "_404-module--container--b557a";
export var error = "_404-module--error--b4931";
export var errorContainer = "_404-module--errorContainer--65e6a";
export var errorText = "_404-module--errorText--968ca";
export var errorTitle = "_404-module--errorTitle--71df0";
export var logo = "_404-module--logo--2e5d3";
export var textStyleLargeTitle = "_404-module--text-style-large-title--62606";
export var textStyleLargestTitle = "_404-module--text-style-largest-title--e885d";
export var textStyleMediumTitle = "_404-module--text-style-medium-title--a9911";
export var textStyleSmallTitle = "_404-module--text-style-small-title--3427e";