import { Link } from 'gatsby';
import React from 'react';
import { useGlobalState } from '../state/globalStateContext';
import { clsx } from '../utils/utils';

import * as styles from './Footer.module.scss';

export interface FooterProps {
  privacyPolicyPage?: {
    slug: {
      current: string;
    };
  };
  noBackground?: boolean;
  padding: string;
  withPageTheme?: boolean;
  additionalCopyrightText?: string;
}

const Footer = ({
  privacyPolicyPage,
  withPageTheme,
  noBackground,
  padding,
  additionalCopyrightText,
}: FooterProps): React.ReactElement => {
  const { theme } = useGlobalState();

  return (
    <footer
      className={clsx(
        styles.footer,
        withPageTheme && styles.withPageTheme,
        noBackground && styles.noBackground,
      )}
      style={{ padding: padding }}
      data-theme={theme === 'light' ? 'light' : ''}
    >
      <div className={clsx(styles.footerContainer, !privacyPolicyPage && styles.noPrivacyPolicy)}>
        {privacyPolicyPage && (
          <Link to={'/' + privacyPolicyPage.slug.current} className={styles.privacyPolicyLink}>
            Privacy Policy
          </Link>
        )}
        <div className={styles.copyrightContainer}>
          <div className={styles.line}></div>
          <span>
            © {new Date().getFullYear()} {additionalCopyrightText}
            <a
              className={styles.hookPointLink}
              href="https://hookpoint.com/"
              target="_blank"
              rel="noopener"
              referrerPolicy="unsafe-url"
            >
              Hook Point
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
